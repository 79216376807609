import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Col, Row } from "react-bootstrap"
import { Facebook, Instagram } from "react-bootstrap-icons"
import MessangerChat from "../components/messangerChat"
import AppStoreBadge from "../components/appStoreBadge"
import GooglePlayBadge from "../components/googlePlayBadge"
import YoutubeChannel from "../components/youtubeChannel"
import ItunesPodcast from "../components/itunesPodcast"
import SpotifyPodcast from "../components/spotifyPodcast"
//import BilettoWidget from "../components/bilettoWidget"

// styles
const headingAccentStyles = {
  color: "#93092b",
}

const logoStyles = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  width: 250,
  marginBottom: "25px",
}

const verticalCenterStyles = {
  minHeight: "100%" /* Fallback for browsers do NOT support vh unit */,
  // eslint-disable-next-line no-dupe-keys
  minHeight: "100vh" /* These two lines are counted as one :-)       */,

  display: "flex",
  alignItems: "center",
}

const socialStyles = {
  /*display: "inline"*/
  margin: "10px",
}

const IndexPage = () => {
  return (
    <div style={verticalCenterStyles}>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <StaticImage
              style={logoStyles}
              alt="Vinkoll logotyp"
              src="../images/VINKOLL_RGB_150DPI.png"
            />
            <p className="lead" style={headingAccentStyles}>
              Vinkoll är en gratis app som samlar Sveriges alla vinprovningar på
              ett ställe för att du enkelt skall hitta dem. Ladda ner Vinkoll
              från AppStore eller Google Play.
            </p>
          </Col>
          <Col
            className="text-center"
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            <h3>Ladda ner appen</h3>
            <AppStoreBadge />
            <br />
            <GooglePlayBadge />
            <br />
            <br />
            <h3>Titta / Lyssna</h3>
            <YoutubeChannel />
            <br />
            <ItunesPodcast />
          </Col>
        </Row>
        <Row className="justify-content-md-center">{SpotifyPodcast()}</Row>
        <Row className="justify-content-md-center">
          <Col className="text-center">
            <a
              href="https://www.facebook.com/Vinkoll.se"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook color="royalblue" size={30} style={socialStyles} />
            </a>
            <a
              href="https://www.instagram.com/vinkoll.se"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram color="#cf2058" size={30} style={socialStyles} />
            </a>
          </Col>
        </Row>
      </Container>
      <MessangerChat />
    </div>
  )
}

export default IndexPage
