import * as React from "react"

const SpotifyPodcast = () => {
  const spotifyIframeStyle = {
    margin: "25px",
    borderRadius: "12px",
    maxWidth: "550px",
  }
  return (
    <iframe
      style={spotifyIframeStyle}
      src="https://open.spotify.com/embed/show/1bWkE94nBSrx4KI8mBysSa?utm_source=generator&theme=0"
      width="100%"
      height="152"
      frameBorder="0"
      allowFullScreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    ></iframe>
  )
}

export default SpotifyPodcast
